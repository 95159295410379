// utils/formatAmount.js
export const formatAmount = (amount, currency) => {
  if (!amount) return "-";

  const fiatCurrencies = {
    USD: "en-US",
    RON: "ro-RO",
    DOP: "es-DO",
    CNY: "zh-CN",
    CHF: "de-CH",
    EUR: "de-DE",
    BRL: "pt-BR",
    BGN: "bg-BG",
    AED: "ar-AE",
  };
  const cryptoCurrencies = ["ETH", "BTC"];

  const options = { style: "currency", currency: currency };

  if (cryptoCurrencies.includes(currency)) {
    return (
      amount.toFixed(6).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " " + currency
    );
  }

  console.log("FROM UTG : ", currency, fiatCurrencies[currency]);
  return new Intl.NumberFormat(fiatCurrencies[currency], options).format(
    amount
  );

  // if (fiatCurrencies.includes(currency)) {
  //   return (
  //     amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " " + currency
  //   );
  // }

  // return amount;
};
