/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { GetService } from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";
import { format, parseISO } from "date-fns";
import { Link, NavLink } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import TablePagination from "@mui/material/TablePagination";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import './styles.css';
import { Navigation, Pagination } from "swiper/modules";
import { formatAmount } from "../../../../Helper/utils";

export default function GroupsBalance() {
  const [groupData, setgroupData] = useState();
  const [referencelist, setreferencelist] = useState();
  const [totalPages, setTotalPages] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [userLimit, setUserLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [SearchReferent, setSearchReferent] = useState("");
  const [activityTag, setActivityTag] = useState(true);
  const [passivityTag, setPassivityTag] = useState(false);
  const [TagFilter, setTagFilter] = useState("Activity");
  const [dataObj, setdataObj] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPage(0);
  };

  function getgrouplisting() {
    setLoading(true);
    GetService(
      `${
        API_URL.GROUP_Balance_LISTING
      }?page=${currentPage}&size=${userLimit}&search=${encodeURIComponent(
        searchValue
      )}&ref_id=${SearchReferent}&tag=${TagFilter}`
    ).then((res) => {
      setLoading(false);
      console.log(res);
      if (res?.data?.status) {
        setgroupData(res?.data?.data?.list || []);
        setdataObj(
          res?.data?.data?.totalAmounts ? res?.data?.data?.totalAmounts : ""
        );
        setTotalPages(res.data?.data?.totalPages || 0);
        setTotal(res?.data?.data?.totalItems || 0);
      }
    });
  }

  function getreferentlisting() {
    GetService(API_URL?.REFERENTS_LISTING_WITHOUT_PAGINATION).then((res) => {
      console.log(res);
      if (res?.data?.status) {
        setreferencelist(res?.data?.data);
      }
    });
  }

  useEffect(() => {
    getreferentlisting();
  }, []);

  useEffect(() => {
    getgrouplisting();
  }, [currentPage, searchValue, SearchReferent, userLimit, TagFilter]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = groupData?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const ExportToCSV = () => {
    const ExportArray = [];
    groupData.map((data) => {
      ExportArray.push({
        Id: data?.id,
        Date: data?.createdAt
          ? format(parseISO(data?.createdAt), "dd-MM-yyyy HH:mm")
          : "NO",
        "A/P": data?.tag,
        "Group Typology": data?.type === "wallet" ? "Wallet" : "Betting Group",
        Status: data?.status === 1 ? "Active" : "Inactive",
        Name: data?.name,
        Referent: data?.referent?.name || "NO",
        "Total-(EUR)": formatAmount(data?.grand_total, "EUR"),
        "(EUR)": formatAmount(data?.groupcurrency?.EUR?.amount, "EUR"),
        "(USD)": formatAmount(data?.groupcurrency?.USD?.amount, "USD"),
        "(CNY)": formatAmount(data?.groupcurrency?.CNY?.amount, "CNY"),
        "(BTC)": formatAmount(data?.groupcurrency?.BTC?.amount, "BTC"),
        "(ETH)": formatAmount(data?.groupcurrency?.ETH?.amount, "ETH"),
        "(RON)": formatAmount(data?.groupcurrency?.RON?.amount, "RON"),
        "(CHF)": formatAmount(data?.groupcurrency?.CHF?.amount, "CHF"),
        "(DOP)": formatAmount(data?.groupcurrency?.DOP?.amount, "DOP"),
        "(BRL)": formatAmount(data?.groupcurrency?.BRL?.amount, "BRL"),
        "(BGN)": formatAmount(data?.groupcurrency?.BGN?.amount, "BGN"),
        "(AED)": formatAmount(data?.groupcurrency?.AED?.amount, "AED"),
      });
    });

    const keys = Object.keys(ExportArray[0]);

    // Create CSV content with keys as headers and data values as rows
    const csvContent =
      "data:text/csv;charset=utf-8," +
      keys.join(",") +
      "\n" +
      ExportArray.map((row) => keys.map((key) => row[key]).join(",")).join(
        "\n"
      );

    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "Groups_Balance_List.csv");

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link programmatically to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (activityTag) {
      setTagFilter("Activity");
    } else if (passivityTag) {
      setTagFilter("Passivity");
    } else {
      setTagFilter("");
    }
  }, [activityTag, passivityTag]);

  function OnTagClick(tag) {
    if (tag === "Activity") {
      setActivityTag(!activityTag);
      if (passivityTag) {
        setPassivityTag(false);
      }
    }
    if (tag === "Passivity") {
      setPassivityTag(!passivityTag);
      if (activityTag) {
        setActivityTag(false);
      }
    }
  }

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-6 d-flex">
              <h3 className="fs-30 text-secondprimary">Groups Balance</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <span className="breadcrumb-item active">Groups Balance</span>
                </ol>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row  mb-4">
                    <div className="col-12 d-flex">
                      <div className="col-md-4 pl-2">
                        <label htmlFor="search-input" className="search-label">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </label>
                        <input
                          type="search"
                          id="search-input"
                          className="form-control pl-5 form-control-sm"
                          placeholder="Search"
                          onChange={(e) => setsearchValue(e.target.value)}
                        />
                      </div>

                      <div className="col-2">
                        <select
                          onChange={(e) => setSearchReferent(e.target.value)}
                          className={`form-control form-control-sm`}
                        >
                          <option value={""}>Select Referent</option>
                          {referencelist &&
                            referencelist?.map((item, i) => (
                              <option value={item?.id} key={i}>
                                {item?.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="col-md-2 ml-auto">
                        <button
                          className="badge badge-outline-primary fa-1x rounded float-right"
                          onClick={ExportToCSV}
                        >
                          <i className="fa fa-upload" aria-hidden="true"></i>{" "}
                          Export
                        </button>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mt-4">
                        <button
                          type="button"
                          onClick={() => OnTagClick("Activity")}
                          className={`btn btn-sm ml-3 rounded-pill ${
                            TagFilter === "Activity"
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                        >
                          <i class="fa-solid fa-arrow-trend-up"></i> Activity
                        </button>
                        <button
                          type="button"
                          onClick={() => OnTagClick("Passivity")}
                          className={`btn btn-sm ml-3 rounded-pill ${
                            TagFilter === "Passivity"
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                        >
                          <i class="fa-solid fa-arrow-trend-down"></i> Passivity
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <div className="d-flex">
                      <div
                        className="col-2 px-0"
                        style={{ borderRight: "2px solid gray" }}
                      >
                        <h6 className="color fs-16 mb-0">Balance Monitor</h6>
                        <h2 className="my-2 color">
                          <span className="fs-16 text-danger">
                            {formatAmount(dataObj?.grand_total, "EUR")}
                            {/* {dataObj?.grand_total?.toLocaleString()} EUR */}
                          </span>
                        </h2>
                      </div>
                      <div className="col-10">
                        <Swiper
                          navigation={true}
                          slidesPerView={4}
                          spaceBetween={20}
                          modules={[Navigation]}
                          className="mySwiper bgm"
                        >
                          {dataObj && dataObj?.EUR !== 0 && (
                            <SwiperSlide className="groupPersonalCard_4 rounded-lg">
                              <div className="card groupPersonalCard groupPersonalCard_4 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="color fs-16 mb-0 font-weight-normal">
                                      EUR Balance
                                    </h6>
                                    {/* <i className="fa-solid fa-chart-simple fs-16"></i> */}
                                  </div>
                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {" "}
                                      {formatAmount(dataObj?.EUR, "EUR")}
                                      {/* {dataObj?.EUR?.toLocaleString()}{" "} */}
                                    </span>
                                  </h2>
                                  {/* <p className="mb-0  font-weight-bold">
                            <span className="text-success">5.20%</span> &nbsp;
                            Last 7 days
                          </p> */}
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                          {dataObj && dataObj?.USD !== 0 && (
                            <SwiperSlide className="groupPersonalCard_5 rounded-lg">
                              <div className="card d-flex groupPersonalCard color groupPersonalCard_5 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16 font-weight-normal">
                                      USD Balance
                                    </h6>
                                  </div>

                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {" "}
                                      {formatAmount(dataObj?.USD, "USD")}
                                      {/* {dataObj?.USD?.toLocaleString()}{" "} */}
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                          {dataObj && dataObj?.CNY !== 0 && (
                            <SwiperSlide className="groupPersonalCard_6 rounded-lg">
                              <div className="card groupPersonalCard groupPersonalCard_6 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16 font-weight-normal">
                                      CNY Balance
                                    </h6>
                                  </div>

                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {" "}
                                      {formatAmount(dataObj?.CNY, "CNY")}
                                      {/* {dataObj?.CNY?.toLocaleString()}{" "} */}
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                          {dataObj && dataObj?.BTC !== 0 && (
                            <SwiperSlide className="groupPersonalCard_7 rounded-lg">
                              <div className="card groupPersonalCard groupPersonalCard_7 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16 font-weight-normal">
                                      BTC Balance
                                    </h6>
                                  </div>

                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {" "}
                                      {formatAmount(dataObj?.BTC, "BTC")}
                                      {/* {dataObj?.BTC?.toLocaleString()} */}
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                          {dataObj && dataObj?.ETH !== 0 && (
                            <SwiperSlide className="groupPersonalCard_1 rounded-lg">
                              <div className="card groupPersonalCard groupPersonalCard_1 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16 font-weight-normal">
                                      ETH Balance
                                    </h6>
                                  </div>

                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {" "}
                                      {formatAmount(dataObj?.ETH, "ETH")}
                                      {/* {dataObj?.ETH?.toLocaleString()} */}
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                          {dataObj && dataObj?.RON !== 0 && (
                            <SwiperSlide className="groupPersonalCard_2 rounded-lg">
                              <div className="card groupPersonalCard groupPersonalCard_2 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16 font-weight-normal">
                                      RON Balance
                                    </h6>
                                  </div>

                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {" "}
                                      {formatAmount(dataObj?.RON, "RON")}
                                      {/* {dataObj?.RON?.toLocaleString()} */}
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                          {dataObj && dataObj?.CHF !== 0 && (
                            <SwiperSlide className="groupPersonalCard_3 rounded-lg">
                              <div className="card groupPersonalCard groupPersonalCard_3 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16 font-weight-normal">
                                      CHF Balance
                                    </h6>
                                  </div>

                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {" "}
                                      {formatAmount(dataObj?.CHF, "CHF")}
                                      {/* {dataObj?.CHF?.toLocaleString()} */}
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                          {dataObj && dataObj?.DOP !== 0 && (
                            <SwiperSlide className="groupPersonalCard_8 rounded-lg">
                              <div className="card groupPersonalCard groupPersonalCard_8 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16 font-weight-normal">
                                      DOP Balance
                                    </h6>
                                  </div>

                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {" "}
                                      {formatAmount(dataObj?.DOP, "DOP")}
                                      {/* {dataObj?.DOP?.toLocaleString()} */}
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                          {dataObj && dataObj?.BRL !== 0 && (
                            <SwiperSlide className="groupPersonalCard_9 rounded-lg">
                              <div className="card groupPersonalCard groupPersonalCard_9 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16 font-weight-normal">
                                      BRL Balance
                                    </h6>
                                  </div>

                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {" "}
                                      {formatAmount(dataObj?.BRL, "BRL")}
                                      {/* {dataObj?.BRL?.toLocaleString()} */}
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                          {dataObj && dataObj?.BGN !== 0 && (
                            <SwiperSlide className="groupPersonalCard_10 rounded-lg">
                              <div className="card groupPersonalCard groupPersonalCard_10 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16 font-weight-normal">
                                      BGN Balance
                                    </h6>
                                  </div>

                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {" "}
                                      {formatAmount(dataObj?.BGN, "BGN")}
                                      {/* {dataObj?.BGN?.toLocaleString()} */}
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                          {dataObj && dataObj?.AED !== 0 && (
                            <SwiperSlide className="groupPersonalCard_11 rounded-lg">
                              <div className="card groupPersonalCard groupPersonalCard_11 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16 font-weight-normal">
                                      AED Balance
                                    </h6>
                                  </div>

                                  <h2 className="my-2 color text-left">
                                    <span className="fs-16">
                                      {formatAmount(dataObj?.AED, "AED")}

                                      {/* {dataObj?.AED?.toLocaleString()} */}
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                        </Swiper>
                      </div>
                    </div>
                  </div>

                  <div className="row px-3 mt-3">
                    <div class="table-responsive pb-1">
                      {!loading && (
                        <table class="table  dataTable no-footer table-hover">
                          <thead>
                            <tr>
                              <th className="pr-2">S.No</th>
                              <th className="px-2">Typology</th>
                              <th
                                className={getHeaderClassName("status")}
                                onClick={() => handleSort("status")}
                              >
                                Status
                              </th>
                              <th
                                className={`${getHeaderClassName("name")} px-4`}
                                onClick={() => handleSort("name")}
                              >
                                Name
                              </th>
                              <th className="px-4">Referent</th>
                              <th className="px-3">Total-(EUR)</th>
                              {dataObj && dataObj?.EUR !== 0 && (
                                <th className="px-3">(EUR)</th>
                              )}
                              {dataObj && dataObj?.USD !== 0 && (
                                <th className="px-3">(USD)</th>
                              )}
                              {dataObj && dataObj?.CNY !== 0 && (
                                <th className="px-3">(CNY)</th>
                              )}
                              {dataObj && dataObj?.BTC !== 0 && (
                                <th className="px-3">(BTC)</th>
                              )}
                              {dataObj && dataObj?.ETH !== 0 && (
                                <th className="px-3">(ETH)</th>
                              )}
                              {dataObj && dataObj?.RON !== 0 && (
                                <th className="px-3">(RON)</th>
                              )}
                              {dataObj && dataObj?.CHF !== 0 && (
                                <th className="px-3">(CHF)</th>
                              )}
                              {dataObj && dataObj?.DOP !== 0 && (
                                <th className="px-3">(DOP)</th>
                              )}
                              {dataObj && dataObj?.BRL !== 0 && (
                                <th className="px-3">(BRL)</th>
                              )}
                              {dataObj && dataObj?.BGN !== 0 && (
                                <th className="px-3">(BGN)</th>
                              )}
                              {dataObj && dataObj?.AED !== 0 && (
                                <th className="px-3">(AED)</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {groupData &&
                              sortedData?.map((item, i) => (
                                <tr key={i}>
                                  <td className="">
                                    <span className="">
                                      {i + currentPage * userLimit + 1}
                                    </span>
                                  </td>
                                  <td className=" ">
                                    {item?.type === "wallet" ? (
                                      <i className="mr-1 fa-solid fa-wallet"></i>
                                    ) : (
                                      <i className="mr-1 fa-solid fa-futbol"></i>
                                    )}
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        item?.status === 1
                                          ? "badge badge-success"
                                          : "badge badge-danger"
                                      }
                                    >
                                      {item?.status ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  <td className=" ">
                                    <span
                                      className={`mr-1 ${
                                        item?.tag === "Passivity"
                                          ? "badge-dot-rd"
                                          : item?.tag === "Activity"
                                          ? "badge-dot-gr"
                                          : ""
                                      }`}
                                    ></span>
                                    <Link
                                      to={`/panel/view_group/${item?.id}/personal_data`}
                                    >
                                      {item?.name}
                                    </Link>
                                  </td>
                                  <td className=" ">
                                    <Link
                                      to={`/panel/view_user/${item?.ref_id}/referent`}
                                    >
                                      {item?.referent
                                        ? item?.referent?.name
                                        : "NO"}
                                    </Link>
                                  </td>
                                  <td
                                    className={
                                      item?.grand_total > 0
                                        ? "text-success font-weight-medium"
                                        : item?.grand_total < 0
                                        ? "text-danger font-weight-medium"
                                        : ""
                                    }
                                  >
                                    {formatAmount(item?.grand_total, "EUR")}
                                    {/* {item?.grand_total?.toLocaleString()} */}
                                  </td>

                                  {dataObj?.EUR !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.EUR?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.EUR?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.EUR?.amount,
                                        "EUR"
                                      )}
                                    </td>
                                  )}

                                  {dataObj?.USD !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.USD?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.USD?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.USD?.amount,
                                        "USD"
                                      )}
                                    </td>
                                  )}

                                  {dataObj?.CNY !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.CNY?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.CNY?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.CNY?.amount,
                                        "CNY"
                                      )}
                                    </td>
                                  )}

                                  {dataObj?.BTC !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.BTC?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.BTC?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.BTC?.amount,
                                        "BTC"
                                      )}
                                    </td>
                                  )}

                                  {dataObj?.ETH !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.ETH?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.ETH?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.ETH?.amount,
                                        "ETH"
                                      )}
                                    </td>
                                  )}

                                  {dataObj?.RON !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.RON?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.RON?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.RON?.amount,
                                        "RON"
                                      )}
                                    </td>
                                  )}

                                  {dataObj?.CHF !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.CHF?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.CHF?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.CHF?.amount,
                                        "CHF"
                                      )}
                                    </td>
                                  )}

                                  {dataObj?.DOP !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.DOP?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.DOP?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.DOP?.amount,
                                        "DOP"
                                      )}
                                    </td>
                                  )}

                                  {dataObj?.BRL !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.BRL?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.BRL?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.BRL?.amount,
                                        "BRL"
                                      )}
                                    </td>
                                  )}

                                  {dataObj?.BGN !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.BGN?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.BGN?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.BGN?.amount,
                                        "BGN"
                                      )}
                                    </td>
                                  )}

                                  {dataObj?.AED !== 0 && (
                                    <td
                                      className={
                                        item?.groupcurrency?.AED?.amount > 0
                                          ? "text-success font-weight-medium"
                                          : item?.groupcurrency?.AED?.amount < 0
                                          ? "text-danger font-weight-medium"
                                          : ""
                                      }
                                    >
                                      {formatAmount(
                                        item?.groupcurrency?.AED?.amount,
                                        "AED"
                                      )}
                                    </td>
                                  )}
                                </tr>
                              ))}
                            {groupData?.length === 0 && (
                              <tr>
                                <td colSpan="17" className="text-center">
                                  <h6 className="p-3"> No Data Found </h6>
                                </td>
                              </tr>
                            )}
                            <tr></tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                    {loading ? (
                      <div className="LoaderSpinner">
                        <MoonLoader
                          color={"#003bff"}
                          loading={loading}
                          speedMultiplier={0.6}
                          size={50}
                        />
                        <div>Loading... Please wait.</div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mt-4 ml-auto">
                      <TablePagination
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
