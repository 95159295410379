/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Pagination from "../../../../Helper/Pagination";
import Switch from "@mui/material/Switch";
import { API_URL } from "../../../../services/ApiService";
import { GetService, PostService } from "../../../../services/ConstantService";
import { useFormik } from "formik";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import TablePagination from "@mui/material/TablePagination";

export default function Profiles() {
  const [ProfiloList, setProfiloList] = useState();
  const [referencelist, setreferencelist] = useState();
  const [totalPages, setTotalPages] = useState();
  const [submit, setsubmit] = useState(false);
  const [currencylist, setcurrencylist] = useState();
  const [grouplist, setgrouplist] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [S_No_Count, setCount] = useState(1);
  const [userLimit, setUserLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedState, setselectedState] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value);
    // console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPage(0);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e - 1);
  };

  const handleReset = () => {
    formik.resetForm();
    setsubmit(false);
    setDisabledField(false);
  };

  const changeStatus = async (id, status) => {
    const data = {
      id: id,
      status: status === 1 ? 0 : 1,
    };

    PostService(API_URL.CHANGE_STATUS_PROFILO, data).then(
      (res) => {
        if (res.data.status) {
          showSuccessAlert(res?.data?.message, getProfilolisting);
        } else {
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
      }
    );
  };

  function getProfilolisting() {
    setLoading(true);
    GetService(
      `${API_URL.GET_PROFILO}?page=${currentPage}&size=${userLimit}`
    ).then((res) => {
      setLoading(false);
      if (res?.status) {
        setProfiloList(res?.data?.data?.list);
        setTotalPages(res.data?.data?.totalPages);
        setTotal(res?.data?.data?.totalItems);
      }
    });
  }

  function getreferencelisting() {
    GetService(API_URL.GET_REFERENCE).then((res) => {
      if (res?.status) {
        setreferencelist(res?.data?.data?.list);
      }
    });
  }
  function getgrouplisting() {
    GetService(API_URL.GROUP_LISTING).then((res) => {
      if (res?.status) {
        setgrouplist(res?.data?.data?.list);
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      freeroll: "",
      action: "",
      tasse: "",
      type_id: 1,
      type: "Gruppo",
      status: 1,
    },
    validate: (values) => {
      const errors = {};

      // Validate 'name' field
      if (!values.name) {
        errors.name = "Name is required";
      }

      return errors;
    },

    onSubmit: (values) => {
      console.log(values);
      if (formik.isValid) {
        setLoading(true);
        const route = selectedState
          ? API_URL.UPDATE_PROFILO
          : API_URL.CREATE_PROFILO;
        if (selectedState) {
          values.id = selectedState?.id;
        }
        // values.type_id = 1; temp
        values.freeroll = 0;
        PostService(route, values).then(
          (res) => {
            setLoading(false);
            if (res?.data.status) {
              showSuccessAlert(res?.data?.message, getProfilolisting);
              document.getElementById("closeModal").click();
            } else {
              showErrorAlert(res.data?.message);
            }
          },
          (err) => {
            showErrorAlert(err.data?.message);
          }
        );
      }
    },
  });

  const [DisabledField, setDisabledField] = useState(false);

  function Setdata(values) {
    setDisabledField(true);
    console.log(values);
    formik.setValues({
      name: values.profile_name,
      freeroll: values.freeroll,
      action: values.action,
      tasse: values.tasse,
      type_id: 1,
      type: values?.type,
      status: 1,
    });
    setselectedState(values);
  }

  useEffect(() => {
    getProfilolisting();
    getgrouplisting();
    getreferencelisting();
  }, [currentPage, userLimit]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = ProfiloList?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 d-flex">
              <h3 className="fs-30 text-secondprimary">Profiles</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link className="breadcrumb-item active">Profiles</Link>
                </ol>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row px-3">
                    <div className="col-md-4 offset-md-8 p-0">
                      <nav className="navbar p-0"></nav>
                    </div>
                    <div class="table-responsive mt-4 col-12">
                      {!loading && (
                        <table class="table dataTable no-footer table-hover">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th
                                className={getHeaderClassName("id")}
                                onClick={() => handleSort("id")}
                              >
                                ID
                              </th>
                              <th
                                className={getHeaderClassName("profile_name")}
                                onClick={() => handleSort("profile_name")}
                              >
                                Nome
                              </th>
                              <th
                                className={getHeaderClassName("tasse")}
                                onClick={() => handleSort("tasse")}
                              >
                                Tasse
                              </th>
                              <th
                                className={getHeaderClassName("action")}
                                onClick={() => handleSort("action")}
                              >
                                Action
                              </th>
                              <th
                                className={getHeaderClassName("freeroll")}
                                onClick={() => handleSort("freeroll")}
                              >
                                FreeRoll
                              </th>
                              <th
                                className={getHeaderClassName("status")}
                                onClick={() => handleSort("status")}
                              >
                                Stato
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ProfiloList &&
                              sortedData?.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <span>
                                      {i + currentPage * userLimit + 1}
                                    </span>
                                  </td>
                                  <td>{item?.id}</td>
                                  <td>
                                    <Link
                                      to={`/panel/view_user/${item?.id}/profile`}
                                    >
                                      {item?.profile_name}
                                    </Link>
                                  </td>
                                  <td>{item?.tasse}</td>
                                  <td>{item?.action}</td>
                                  <td>
                                    {item?.freeroll ? item?.freeroll : "NO"}
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        item?.status === 1
                                          ? "badge badge-success"
                                          : "badge badge-danger"
                                      }
                                    >
                                      {item?.status ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      data-toggle="modal"
                                      data-target="#AddModal"
                                      title="Edit"
                                      style={{ cursor: "pointer" }}
                                      className="mx-2"
                                      onClick={() => Setdata(item)}
                                    >
                                      <i class="fa-solid fa-pen"></i>
                                    </span>
                                    <span className="toggle-switch">
                                      <Switch
                                        checked={item?.status}
                                        size="small"
                                        color="primary"
                                        onChange={() => {
                                          changeStatus(item?.id, item?.status);
                                        }}
                                        inputProps={{
                                          "aria-label": "toggle switch",
                                        }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {ProfiloList && ProfiloList.length === 0 && (
                              <tr>
                                <td colSpan="8" className="text-center">
                                  <h6 className="p-3"> No Data Found </h6>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td colSpan={8}>
                                <div className="d-flex justify-content-end">
                                  <div
                                    className="add_circle bg-primary"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    onClick={() => {
                                      handleReset();
                                      setselectedState("");
                                    }}
                                  >
                                    <i class="fa-solid fa-plus"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}

                      {loading ? (
                        <div className="LoaderSpinner">
                          <MoonLoader
                            color={"#003bff"}
                            loading={loading}
                            speedMultiplier={0.6}
                            size={50}
                          />
                          <div>Loading... Please wait.</div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="mt-4">
                        {/* <Pagination
                          counting={currentPage * userLimit}
                          totaldata={total}
                          pagecount={totalPages}
                          onChangePage={handlePageClick}
                          activePage={currentPage}
                        /> */}
                        <TablePagination
                          component="div"
                          count={total}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* / Add MODAL-------------------- / */}
      <div
        className="modal fade"
        id="AddModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body py-4">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-7 col-12">
                    <h4
                      className="modal-title h3 mb-4 mt-1"
                      id="exampleModalLabel"
                    >
                      Aggiungi Profilo
                    </h4>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.name && submit ? "border_error" : ""
                          }`}
                          placeholder="Nome Profilo"
                          name="name"
                          value={formik.values.name}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-3 row">
                      <div className="d-flex">
                        <input
                          type="radio"
                          id="Gruppo"
                          className={`mx-2 ${
                            formik.errors.type && submit ? "border_error" : ""
                          }`}
                          name="type"
                          value="Gruppo"
                          checked={formik?.values?.type === "Gruppo"}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                        <label className="font-weight-bold">Gruppo</label>
                      </div>
                      <div className="d-flex ml-4">
                        <input
                          type="radio"
                          className={`mx-2 ${
                            formik.errors.type && submit ? "border_error" : ""
                          }`}
                          name="type"
                          value="Referents"
                          checked={formik?.values?.type === "Referents"}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          id="Referents"
                          disabled={DisabledField}
                        ></input>
                        <label className="font-weight-bold">Referents</label>
                      </div>
                    </div>

                    {formik.values.type === "Gruppo" && (
                      <div className="col-12">
                        <select
                          className="form-control"
                          name="type_id"
                          onChange={formik.handleChange}
                          value={formik.values.type_id}
                          disabled={DisabledField}
                        >
                          <option>Select Gruppo</option>
                          {grouplist &&
                            grouplist?.map((item) => (
                              <option value={item?.id}>{item?.name}</option>
                            ))}
                        </select>
                      </div>
                    )}

                    {formik.values.type === "Referents" && (
                      <div className="col-12">
                        <select
                          className="form-control"
                          name="type_id"
                          onChange={formik.handleChange}
                          value={formik.values.type_id}
                          disabled={DisabledField}
                        >
                          <option>Select Referents</option>
                          {referencelist &&
                            referencelist?.map((item) => (
                              <option value={item?.id}>{item?.name}</option>
                            ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-5 col-12">
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="close px-3"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleReset}
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="align-items-center d-flex my-2 row mt-3">
                      <div className="col-4">
                        <div className="">Tasse</div>
                      </div>
                      <div className="col-5">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.tasse && submit ? "border_error" : ""
                          }`}
                          name="tasse"
                          value={formik.values.tasse}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                      <div className="col-3">
                        <div className="">%</div>
                      </div>
                    </div>

                    <div className="align-items-center d-flex my-2 row">
                      <div className="col-4">
                        <div className="">Action</div>
                      </div>
                      <div className="col-5">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.action && submit ? "border_error" : ""
                          }`}
                          name="action"
                          value={formik.values.action}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                      <div className="col-3">
                        <div className="">%</div>
                      </div>
                    </div>

                    <div className="align-items-center d-flex my-2 row">
                      <div className="col-4">
                        <div className="">Free Roll</div>
                      </div>
                      <div className="col-5">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.freeroll && submit
                              ? "border_error"
                              : ""
                          }`}
                          name="freeroll"
                          value={formik.values.freeroll}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                      <div className="col-3">
                        <div className="">%</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3">
                    <span
                      className="add_circle bg-danger"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="closeModal"
                      onClick={handleReset}
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </span>
                    {!selectedState && (
                      <span className="add_circle mx-2 bg-success">
                        <button
                          type="submit"
                          className="btn text-white"
                          onClick={() => setsubmit(true)}
                        >
                          <i class="fa-solid fa-check"></i>
                        </button>
                      </span>
                    )}

                    {selectedState && DisabledField && (
                      <span className="add_circle mx-2 bg-info">
                        <button
                          type="submit"
                          className="btn text-white"
                          onClick={() => setDisabledField(false)}
                        >
                          <i class="fa-solid fa-pen"></i>
                        </button>
                      </span>
                    )}

                    {selectedState && !DisabledField && (
                      <span
                        className="add_circle mx-2 bg-success"
                        onClick={() => setsubmit(true)}
                      >
                        <button type="submit" className="btn text-white">
                          <i class="fa-solid fa-check"></i>
                        </button>
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* / Add MODAL- End ------------------- / */}

      {/* / DELETE MODAL-------------------- / */}
      <div
        className="modal fade"
        id="DeleteModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete User !
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6> Are you sure you want to delete this User ? </h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="closeDeleteModal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                // onClick={() => deleteuser()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / DELETE MODAL- End ------------------- / */}
    </div>
  );
}
